import React from "react";
import { Helmet } from "react-helmet";

// layout
import StandardLayout from "./layouts/standard";

// pages
import Home from "./pages/home/home";
import EligibilityLanding from "./pages/eligibilityLanding/EligibilityLanding";
import Approved from "./pages/eligibilityDecision/approved";
import NotApproved from "./pages/eligibilityDecision/notApproved";
import NotMatched from "./pages/eligibilityDecision/notMatched";
import EligibilityChecker from "./pages/eligibilityChecker/eligibilityChecker";
import AboutYou from "./pages/eligibilityChecker/form/aboutyou";
import AddressHistory from "./pages/eligibilityChecker/form/addresshistory";
import AboutYourBusiness from "./pages/eligibilityChecker/form/aboutbusiness";
import Confirmation from "./pages/eligibilityChecker/form/confirmation";

// import { Context } from "./stores/store";
import Store from "./stores/store";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AboutBusiness from "./pages/eligibilityChecker/form/about-business";
import "./App.scss";
import AboutAssets from "./pages/eligibilityChecker/form/about-assets";
import AboutApplicant from "./pages/eligibilityChecker/form/about-applicant";
import CompanySearch from "./pages/eligibilityChecker/form/company-search";
import CompanyDetails from "./pages/eligibilityChecker/form/company-details";
import DecisionAccept from "./pages/eligibilityChecker/form/decision-accept";
import DecisionRefer from "./pages/eligibilityChecker/form/decision-refer";
import DecisionDecline from "./pages/eligibilityChecker/form/decision-decline";
import DecisionNone from "./pages/eligibilityChecker/form/decision-none";
import DecisionNotAvailable from "./pages/eligibilityChecker/form/decision-not-available";

class App extends React.Component {
  render() {
    return (
      <>
        <Store>
          <Router>
            <div className="calc-main">
              <Switch>
                {/*
                 * ROUTE - /
                 */}
                <Route exact path="/">
                  {() => {
                    window.location.href =
                      "https://www.lombard.co.uk/finance-options/hire-purchase.html";
                    return null; // Prevent React from rendering anything
                  }}
                  {/* <Helmet>
                    <link
                      rel="canonical"
                      href="https://digital.lombard.com"
                    />
                  </Helmet>
                  <StandardLayout>
                    <Home />
                  </StandardLayout> */}
                </Route>

                {/*
                 * ROUTE - /eligibility-check
                 */}
                <Route exact path="/eligibility-check/about-assets">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <AboutAssets />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/about-applicant
                 */}
                <Route exact path="/eligibility-check/about-applicant">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <AboutApplicant />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/company-searech
                 */}
                <Route exact path="/eligibility-check/company-search">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <CompanySearch />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>
                {/*
                 * ROUTE - /eligibility-check/about-business
                 */}
                <Route exact path="/eligibility-check/about-business">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <AboutBusiness />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/company-details
                 */}
                <Route exact path="/eligibility-check/company-details">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <CompanyDetails />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/result
                 */}
                <Route exact path="/eligibility-check/result">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <DecisionAccept />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/outcome
                 */}
                <Route exact path="/eligibility-check/outcome">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <DecisionRefer />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/decision
                 */}
                <Route exact path="/eligibility-check/decision">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <DecisionDecline />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/no-decision
                 */}
                <Route exact path="/eligibility-check/no-decision">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <DecisionNone />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ROUTE - /eligibility-check/not-available
                 */}
                <Route exact path="/eligibility-check/not-available">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <DecisionNotAvailable />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                {/*
                 * ***************************************
                 * OLD routes from SBL
                 * ***************************************
                 */}
                <Route path="/business/loans-and-finance/lending-eligibility-check">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout EC>
                    <EligibilityLanding />
                  </StandardLayout>
                </Route>

                <Route path="/business/loans-and-finance/approved">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <Approved />
                  </StandardLayout>
                </Route>

                <Route path="/business/loans-and-finance/not-approved">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <NotApproved />
                  </StandardLayout>
                </Route>

                <Route path="/business/loans-and-finance/not-matched">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <NotMatched />
                  </StandardLayout>
                </Route>

                {/* <Route path="/eligibility-checker">
                <StandardLayout>
                  <EligibilityChecker />
                </StandardLayout>
                </Route> */}

                {/* Form Steps */}
                <Route
                  exact
                  path="/business/loans-and-finance/eligibility-checker/about-you"
                >
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout EC>
                    <EligibilityChecker>
                      <AboutYou />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                <Route path="/business/loans-and-finance/eligibility-checker/address-history">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout EC>
                    <EligibilityChecker>
                      <AddressHistory />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                <Route path="/business/loans-and-finance/eligibility-checker/about-your-business">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout EC>
                    <EligibilityChecker>
                      <AboutYourBusiness />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>

                <Route path="/business/loans-and-finance/eligibility-checker/confirmation">
                  <Helmet>
                    <meta name="robots" content="noindex" />
                  </Helmet>
                  <StandardLayout>
                    <EligibilityChecker>
                      <Confirmation />
                    </EligibilityChecker>
                  </StandardLayout>
                </Route>
              </Switch>
            </div>
          </Router>
        </Store>
      </>
    );
  }
}

export default App;
